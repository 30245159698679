/*=================================================================*/
/*                     PRELOADER
/*=================================================================*/
#preloader {
  background: #f6f7ff;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 5;
}

#preloader .outer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: $colororange;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*=================================================================*/
/*                     BOOTSTRAP ELEMENTS
/*=================================================================*/
.breadcrumb {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb > .active {
  color: #9c9c9c;
}

/* === Form Control === */
.form-control {
  display: block;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  padding: 10px 24px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: solid 1px #e3e3e3;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:focus {
  border-color: $colororange;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #999;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

.form-control::placeholder {
  color: #999;
}

.form-group {
  margin-bottom: 20px;
}

.input-group-addon {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

/* === Alert === */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 15px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: 0;
  right: 0;
  color: inherit;
}

/* === Progress Bar === */
.progress {
  height: 3px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #ebebeb;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 3px;
  color: #fff;
  text-align: center;
  background-color: $colororange;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
